import React from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import Social from './Social';

const Footer = props => (
  <div className="footer-strip">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="footer">
            <div className="footer-logo">
              <Link to="/">
                <img alt="Iris Buforn - Abogada logotipo" src="/logo.jpg" />
              </Link>
            </div>
            <ul className="footer-menu">
              <li>
                {' '}
                <Link to="/politica-privacidad/">Política de Privacidad</Link>
              </li>
              <li>
                {' '}
                <Link to="/contacto/">Contacto</Link>
              </li>
            </ul>
            <Social />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
);
