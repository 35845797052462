import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const Social = props => (
  <div className="social-bar">
    <div className="container">
    <div id="social">
        <ul className="social">
          {props.data.site.siteMetadata.socialLinks.map(social => (
            <li key={social.name}>
              <a target="_blank" className={'social-'+social.name} href={social.link}>
              {social.name}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            socialLinks {
                name
                link
            }
          }
        }
      }
    `}
    render={data => <Social data={data} />}
  />
);
