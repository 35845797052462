import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery } from 'gatsby';

const TwitterCard = ({
    type = 'summary',
    title = null,
    description = null,
    image = null,
}) => (
    <Helmet>
        {type && <meta property="twitter:card" content={type} />}
        {title && <meta property="twitter:title" content={title} />}
        {description && <meta property="twitter:description" content={description} />}
        {image && <meta property="twitter:image" content={image} />}
    </Helmet>
);

export default TwitterCard;

