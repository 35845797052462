import React from 'react';
import Helmet from 'react-helmet';
import OGFacebook from './OGFacebook';
import TwitterCard from './TwitterCard';
import { StaticQuery, graphql } from 'gatsby';

const SEO = props => (
  <StaticQuery
    query={detailsQuery}
    render={(data) => {
      const title = props.title || data.site.siteMetadata.title;
      const titleTemplate = ((props.title) ? props.title + ' - ' : '') + data.site.siteMetadata.title;
      const description = props.description || data.site.siteMetadata.description;
      const image = props.image || data.site.siteMetadata.image;
      const keywords = props.keywords || data.site.siteMetadata.keywords;
      const url = data.site.siteMetadata.siteUrl + ((props.url) ? props.url : '');
      const urlSlashed = (url.endsWith('/')) ? url : url + '/';
      const fbType = props.fbType || 'website';
      const locale = 'es';
      return (
        <>
        <Helmet
          htmlAttributes={{
            lang: locale,
          }}
          title={title}
          titleTemplate={titleTemplate}
          link={[
            { rel: 'shortcut icon', type: 'image/x-icon', href: '/favicon.ico' }
        ]}
        >
        <meta name="description" content={description}/>
        <meta name="image" content={image}/>
        <meta name="robots" content="index,follow"/>
        <meta name="keywords" content={keywords}/>
        </Helmet>
        <OGFacebook title={titleTemplate} description={description} image={image} url={urlSlashed} type={fbType} locale={locale}/>
        <TwitterCard title={titleTemplate} description={description} image={image}/>
        </>
      );
    }}
  />
);

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
        image
        keywords
      }
    }
  }
`;
