import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery } from 'gatsby';

const OGFacebook = ({
    url = null,
    type = null,
    title = null,
    description = null,
    image = null,
    locale = null,
}) => (
    <Helmet>
        {url && <meta property="og:url" content={url} />}
        {type && <meta property="og:type" content={type} />}
        {title && <meta property="og:title" content={title} />}
        {description && <meta property="og:description" content={description} />}
        {image && <meta property="og:image" content={image} />}
        {locale && <meta property="og:locale" content={locale} />}
    </Helmet>
);

export default OGFacebook;

